/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const LOADER_GENERAL_ENABLED = 'LOADER_GENERAL_ENABLED';
export const LOADER_GENERAL_DISABLED = 'LOADER_GENERAL_DISABLED';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

/* RIGHT SIDEBAR CHAT */
export const RIGHT_SIDEBAR = 'RIGHT_SIDEBAR';

export const types = {
  systemConf: {
    GET_STATE_CONFIG: 'GET_STATE_CONFIG',
  },
  AppFairdent: {
    GET_PATIENTS: 'GET_PATIENTS',
    SET_ALL_PATIENTS: 'SET_ALL_PATIENTS',
    OPEN_MODAL_PREMIOS: 'OPEN_MODAL_PREMIOS',
    CLOSE_MODAL_PREMIOS: 'CLOSE_MODAL_PREMIOS',
    SET_PREMIOS_PACIENTE: 'SET_PREMIOS_PACIENTE',
    OPEN_MODAL_RECOJO: 'OPEN_MODAL_RECOJO',
    CLOSE_MODAL_RECOJO: 'CLOSE_MODAL_RECOJO',
    SAVE_RECOJO_LOADING_TRUE: 'SAVE_RECOJO_LOADING_TRUE',
    SAVE_RECOJO_LOADING_FALSE: 'SAVE_RECOJO_LOADING_FALSE',
    GET_PATIENTS_RETIRO: 'GET_PATIENTS_RETIRO',
    SET_ALL_PATIENTS_RETIRO: 'SET_ALL_PATIENTS_RETIRO',
    CLOSE_ALL_SAVE_RETIRO: 'CLOSE_ALL_SAVE_RETIRO',
    OPEN_MODAL_RETIRO: 'OPEN_MODAL_RETIRO',
    CLOSE_MODAL_RETIRO: 'CLOSE_MODAL_RETIRO',
    SAVE_RETIRO_LOADING_TRUE: 'SAVE_RETIRO_LOADING_TRUE',
    SAVE_RETIRO_LOADING_FALSE: 'SAVE_RETIRO_LOADING_FALSE',
    CLOSE_SAVE_RETIRO: 'CLOSE_SAVE_RETIRO',
    SET_RESPONSE: 'SET_RESPONSE',
  },
  especialista: {
    paciente: {
      actualTratamiento: {
        STEPPER_OPEN_MODAL_TRABAJO: 'STEPPER_OPEN_MODAL_TRABAJO',
        OPEN_MODAL_TRABAJO: 'OPEN_MODAL_TRABAJO',
        GET_PLAN_TRABAJO_SUCCESS: 'GET_PLAN_TRABAJO_SUCCESS',
        CLOSE_MODAL_TRABAJO: 'CLOSE_MODAL_TRABAJO',
        CHECK_ITEM_SELECTED_PLAN: 'CHECK_ITEM_SELECTED_PLAN',
        CHECK_FINISH_CITA_LOADING: 'CHECK_FINISH_CITA_LOADING',
        CHECK_FINISH_CITA_SUCCESS: 'CHECK_FINISH_CITA_SUCCESS',
        CLOSE_MODAL_FINISH_CITA: 'CLOSE_MODAL_FINISH_CITA',
      },
      radiografia: {
        openModalPresupuesto:
          'especialista/paciente/radiografia/openModalPresupuesto',
        getTypesRadioSuccess:
          'especialista/paciente/radiografia/getTypesRadioSuccess',
        closeModalPresupuesto:
          'especialista/paciente/radiografia/closeModalPresupuesto',
        addSelectedtypesRadio:
          'especialista/paciente/radiografia/addSelectedtypesRadio',
        deleteSelectedtypesRadio:
          'especialista/paciente/radiografia/deleteSelectedtypesRadio',
        savePresupuesto: 'especialista/paciente/radiografia/savePresupuesto',
        savePresupuestoSuccess:
          'especialista/paciente/radiografia/savePresupuestoSuccess',
        getCurrentPres: 'especialista/paciente/radiografia/getCurrentPres',
        getCurrentPresSuccess:
          'especialista/paciente/radiografia/getCurrentPresSuccess',
        openModalDeleteRadio:
          'especialista/paciente/radiografia/openModalDeleteRadio',
        closeModalDeleteRadio:
          'especialista/paciente/radiografia/closeModalDeleteRadio',
        requestDeleteRadio:
          'especialista/paciente/radiografia/requestDeleteRadio',
        requestDeleteRadioSuccess:
          'especialista/paciente/radiografia/requestDeleteRadioSuccess',
        openModalEditRadio:
          'especialista/paciente/radiografia/openModalEditRadio',
        closeModalEditRadio:
          'especialista/paciente/radiografia/closeModalEditRadio',
        editSelectedtypesRadio:
          'especialista/paciente/radiografia/editSelectedtypesRadio',
        requestEditRadio: 'especialista/paciente/radiografia/requestEditRadio',
        requestEditRadioSuccess:
          'especialista/paciente/radiografia/requestEditRadioSuccess',
        openModalRadioView:
          'especialista/paciente/radiografia/openModalRadioView',
        radioViewSuccess: 'especialista/paciente/radiografia/radioViewSuccess',
        closeModalRadioView:
          'especialista/paciente/radiografia/closeModalRadioView',
        openModalTrazarRadio:
          'especialista/paciente/radiografia/openModalTrazarRadio',
        closeModalTrazarRadio:
          'especialista/paciente/radiografia/closeModalTrazarRadio',
      },
    },
  },
  admin: {
    balanceSunat: {
      categoria: {
        ganancia: {
          SET_SELECTED_TICKET:
            'admin/balanceSunat/categoria/ganancia/SET_SELECTED_TICKET',
          sendTicketLoading:
            'admin/balanceSunat/categoria/ganancia/sendTicketLoading',
          sendTicketSuccess:
            'admin/balanceSunat/categoria/ganancia/sendTicketSuccess',
          getComprobantes:
            'admin/balanceSunat/categoria/ganancia/getComprobantes',
          getComprobantesSuccess:
            'admin/balanceSunat/categoria/ganancia/getComprobantesSuccess',
          send_register_sunat:
            'admin/balanceSunat/categoria/ganancia/send_register_sunat',
          send_register_sunat_success:
            'admin/balanceSunat/categoria/ganancia/send_register_sunat_success',
          openModalPreviewBoleta:
            'admin/balanceSunat/categoria/ganancia/openModalPreviewBoleta',
          closeModalPreviewBoleta:
            'admin/balanceSunat/categoria/ganancia/closeModalPreviewBoleta',
        },
      },
      diario: {
        selectedTabCategory: 'admin/balanceSunat/diario/selectedTabCategory',
        selectedTabCategorySuccess:
          'admin/balanceSunat/diario/selectedTabCategorySuccess',
        selectedtypeComprob: 'admin/balanceSunat/diario/selectedtypeComprob',
        selectedtypeComprobSuccess:
          'admin/balanceSunat/diario/selectedtypeComprobSuccess',
        setCategoryItems: 'admin/balanceSunat/diario/setCategoryItems',
        getSumComprobSunat: 'admin/balanceSunat/diario/getSumComprobSunat',
        getSumComprobSunatSuccess:
          'admin/balanceSunat/diario/getSumComprobSunatSuccess',
      },
      conversion: {
        getSeachFilter: 'admin/balanceSunat/conversion/getSeachFilter',
        getSeachFilterSuccess:
          'admin/balanceSunat/conversion/getSeachFilterSuccess',
        setSelectecComprob: 'admin/balanceSunat/conversion/setSelectecComprob',
        deleteSelectecComprob:
          'admin/balanceSunat/conversion/deleteSelectecComprob',
        openModalConvert: 'admin/balanceSunat/conversion/openModalConvert',
        closeModalConvert: 'admin/balanceSunat/conversion/closeModalConvert',
        buscarDocumento: 'admin/balanceSunat/conversion/buscarDocumento',
        buscarDocumentoSuccess:
          'admin/balanceSunat/conversion/buscarDocumentoSuccess',
        editSelectedPacienteTicket:
          'admin/balanceSunat/conversion/editSelectedPacienteTicket',
        openModalConfirmation:
          'admin/balanceSunat/conversion/openModalConfirmation',
        closeModalConfirmation:
          'admin/balanceSunat/conversion/closeModalConfirmation',
        makeStepper: 'admin/balanceSunat/conversion/makeStepper',
        saveConvertTickets: 'admin/balanceSunat/conversion/makeStepper',
        saveConvertTicketsSuccess:
          'admin/balanceSunat/conversion/saveConvertTicketsSuccess',
      },
      sunat: {
        getSearchBoletaFactura:
          'admin/balanceSunat/sunat/getSearchBoletaFactura',
        getSearchBoletaFacturaSuccess:
          'admin/balanceSunat/sunat/getSearchBoletaFacturaSuccess',
        openModalEditar: 'admin/balanceSunat/sunat/openModalEditar',
        closeModalEditar: 'admin/balanceSunat/sunat/closeModalEditar',
        buscarDocumento: 'admin/balanceSunat/sunat/buscarDocumento',
        buscarDocumentoSuccess:
          'admin/balanceSunat/sunat/buscarDocumentoSuccess',
        editSelectedPacienteComprob:
          'admin/balanceSunat/sunat/editSelectedPacienteComprob',
        saveBoletaFactura: 'admin/balanceSunat/sunat/saveBoletaFactura',
        saveBoletaFacturaSuccess:
          'admin/balanceSunat/sunat/saveBoletaFacturaSuccess',
        sendBoletaFacturaSunat:
          'admin/balanceSunat/sunat/sendBoletaFacturaSunat',
        sendBoletaFacturaSunatSuccess:
          'admin/balanceSunat/sunat/sendBoletaFacturaSunatSuccess',
      },
    },
  },
  asistente: {
    paciente: {
      recibos: {
        openModalRecibos: 'asistente/paciente/recibos/openModalRecibos',
        closeModalRecibos: 'asistente/paciente/recibos/closeModalRecibos',
        getReciboCitaSuccess: 'asistente/paciente/recibos/getReciboCitaSuccess',
        openModalPago: 'asistente/paciente/recibos/openModalPago',
        closeModalPago: 'asistente/paciente/recibos/closeModalPago',
        addCarPago: 'asistente/paciente/recibos/addCarPago',
        deleteCarPago: 'asistente/paciente/recibos/deleteCarPago',
        deleteAllCarPago: 'asistente/paciente/recibos/deleteAllCarPago',
        getCitaIdPagoAdvance: 'asistente/paciente/recibos/getCitaIdPagoAdvance',
        editConfigPagos: 'asistente/paciente/recibos/editConfigPagos',
        editConfigPagosRadio: 'asistente/paciente/recibos/editConfigPagosRadio',
        getCitaIdPagoAdvanceSuccess:
          'asistente/paciente/recibos/getCitaIdPagoAdvanceSuccess',
        sendRegistroPago: 'asistente/paciente/recibos/sendRegistroPago',
        sendRegistroPagoSuccess:
          'asistente/paciente/recibos/sendRegistroPagoSuccess',
      },
      radiografia: {
        openModalRadiografia:
          'asistente/paciente/radiografia/openModalRadiografia',
        closeModalRadiografia:
          'asistente/paciente/radiografia/closeModalRadiografia',
        getAllRadiografiasSuccess:
          'asistente/paciente/radiografia/getAllRadiografiasSuccess',
        openModalSubirRadiografia:
          'asistente/paciente/radiografia/openModalSubirRadiografia',
        closeModalSubirRadiografia:
          'asistente/paciente/radiografia/closeModalSubirRadiografia',
        subirRadiografiaByCita:
          'asistente/paciente/radiografia/subirRadiografiaByCita',
        subirRadiografiaByCitaSuccess:
          'asistente/paciente/radiografia/subirRadiografiaByCitaSuccess',
      },
    },
  },
  MesaPartes: {
    selectedFilter: 'MesaPartes/selectedFilter',
    GET_TRAMITES_USER_LOADING: 'GET_TRAMITES_USER_LOADING',
    SET_TRAMITES_USER: 'SET_TRAMITES_USER',
    OPEN_MODAL_DETALLES: 'OPEN_MODAL_DETALLES',
    CLOSE_MODAL_DETALLES: 'CLOSE_MODAL_DETALLES',
    SET_DATA_DETALLES: 'SET_DATA_DETALLES',
    OPEN_MODAL_ARCHIVOS: 'OPEN_MODAL_ARCHIVOS',
    CLOSE_MODAL_ARCHIVOS: 'CLOSE_MODAL_ARCHIVOS',
    SET_ARCHIVOS_SEGUIMIENTO: 'SET_ARCHIVOS_SEGUIMIENTO',
    OPEN_MODAL_SEGUIMIENTO: 'OPEN_MODAL_SEGUIMIENTO',
    CLOSE_MODAL_SEGUIMIENTO: 'CLOSE_MODAL_SEGUIMIENTO',
    SET_DATA_HISTORIAL: 'SET_DATA_HISTORIAL',
    OPEN_MODAL_ACCIONES: 'OPEN_MODAL_ACCIONES',
    CLOSE_MODAL_ACCIONES: 'CLOSE_MODAL_ACCIONES',
    LOADING_SAVE_ACCION: 'LOADING_SAVE_ACCION',
    SET_OPCIONES_DERIVAR: 'SET_OPCIONES_DERIVAR',
    SUCCESS_SAVE_ACCION: 'SUCCESS_SAVE_ACCION',
    TIPO_DOCUMENT_LOADING: 'TIPO_DOCUMENT_LOADING',
    TIPO_DOCUMENT_SUCCESS: 'TIPO_DOCUMENT_SUCCESS',
    dashboard: {
      getStates: 'MesaPartes/dashboard/getStates',
      getStatesSuccess: 'MesaPartes/dashboard/getStatesSuccess',
      getRecentDocuments: 'MesaPartes/dashboard/getRecentDocuments',
      getRecentDocumentsSuccess:
        'MesaPartes/dashboard/getRecentDocumentsSuccess',
      setGolbalFilter: 'MesaPartes/dashboard/setGolbalFilter',
    },
    Registro: {
      REGISTER_LOADING: 'REGISTER_LOADING',
      REGISTER_SUCCESS: 'REGISTER_SUCCESS',
      REGISTER_ERROR: 'REGISTER_ERROR',
      TIPO_DOCUMENT_LOADING: 'MesaPartes/Registro/TIPO_DOCUMENT_LOADING',
      TIPO_DOCUMENT_SUCCESS: 'MesaPartes/Registro/TIPO_DOCUMENT_SUCCESS',
    },
    Seguimiento: {
      OPEN_MODAL_ARCHIVOS: 'MesaPartes/Seguimiento/OPEN_MODAL_ARCHIVOS',
      CLOSE_MODAL_ARCHIVOS: 'MesaPartes/Seguimiento/CLOSE_MODAL_ARCHIVOS',
      OPEN_MODAL_SEGUIMIENTO: 'MesaPartes/Seguimiento/OPEN_MODAL_SEGUIMIENTO',
      CLOSE_MODAL_SEGUIMIENTO: 'MesaPartes/Seguimiento/CLOSE_MODAL_SEGUIMIENTO',
      GET_TRAMITES_USER_LOADING:
        'MesaPartes/Seguimiento/GET_TRAMITES_USER_LOADING',
      SET_TRAMITES_USER: 'MesaPartes/Seguimiento/SET_TRAMITES_USER',
      SET_ARCHIVOS_SEGUIMIENTO:
        'MesaPartes/Seguimiento/SET_ARCHIVOS_SEGUIMIENTO',
      SET_DATA_HISTORIAL: 'MesaPartes/Seguimiento/SET_DATA_HISTORIAL',
      SET_VISTO_ARCHIVOS: 'MesaPartes/Seguimiento/SET_VISTO_ARCHIVOS',
    },
  },
  recursosHumanos: {
    controlAsistencia: {
      horasExtras: {
        openModalSolicitud:
          'recursosHumanos/controlAsistencia/horasExtras/openModalSolicitud',
        closeModalSolicitud:
          'recursosHumanos/controlAsistencia/horasExtras/closeModalSolicitud',
        getCargosSolicitudSuccess:
          'recursosHumanos/controlAsistencia/horasExtras/getCargosSolicitudSuccess',
        selectFechaSolicitud:
          'recursosHumanos/controlAsistencia/horasExtras/selectFechaSolicitud',
        selectFechaSolicitudSuccess:
          'recursosHumanos/controlAsistencia/horasExtras/selectFechaSolicitudSuccess',
        clearFechaSolicitud:
          'recursosHumanos/controlAsistencia/horasExtras/clearFechaSolicitud',
        sendSolicitudExtra:
          'recursosHumanos/controlAsistencia/horasExtras/sendSolicitudExtra',
        sendSolicitudExtraSuccess:
          'recursosHumanos/controlAsistencia/horasExtras/sendSolicitudExtraSuccess',
        getPendinSolicitudExtra:
          'recursosHumanos/controlAsistencia/horasExtras/getPendinSolicitudExtra',
        getPendinSolicitudExtraSuccess:
          'recursosHumanos/controlAsistencia/horasExtras/getPendinSolicitudExtraSuccess',
        openModalHoraExtraRevisar:
          'recursosHumanos/controlAsistencia/horasExtras/openModalHoraExtraRevisar',
        closeModalHoraExtraRevisar:
          'recursosHumanos/controlAsistencia/horasExtras/closeModalHoraExtraRevisar',
        sendConfirmationSolicitud:
          'recursosHumanos/controlAsistencia/horasExtras/sendConfirmationSolicitud',
        sendConfirmationSolicitudSuccess:
          'recursosHumanos/controlAsistencia/horasExtras/sendConfirmationSolicitudSuccess',
        selectedOrderOption:
          'recursosHumanos/controlAsistencia/horasExtras/selectedOrderOption',
      },
    },
    tareas: {
      selectEmpleadoTarea: 'recursosHumanos/tareas/selectEmpleadoTarea',
    },
  },
};

export * from './menu/actions';
export * from './settings/actions';
export * from './Auth/actions';
export * from './todo/actions';
export * from './chat/actions';
export * from './surveyList/actions';
export * from './surveyDetail/actions';
