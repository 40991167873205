import { types } from '../../../actions';

export const openModalPresupuesto = (clear = false) => ({
  type: types.especialista.paciente.radiografia.openModalPresupuesto,
  payload: { clear },
});

export const closeModalPresupuesto = () => ({
  type: types.especialista.paciente.radiografia.closeModalPresupuesto,
});

export const getTypesRadioSuccess = (data) => ({
  type: types.especialista.paciente.radiografia.getTypesRadioSuccess,
  payload: { data },
});

export const addSelectedtypesRadio = (data) => ({
  type: types.especialista.paciente.radiografia.addSelectedtypesRadio,
  payload: { data },
});

export const deleteSelectedtypesRadio = (data) => ({
  type: types.especialista.paciente.radiografia.deleteSelectedtypesRadio,
  payload: { data },
});

export const actionSavePresupuesto = (data) => ({
  type: types.especialista.paciente.radiografia.savePresupuesto,
  payload: { data },
});

export const actionSavePresupuestoSuccess = (data) => ({
  type: types.especialista.paciente.radiografia.savePresupuestoSuccess,
  payload: { data },
});

export const getCurrentPres = (data) => ({
  type: types.especialista.paciente.radiografia.getCurrentPres,
  payload: { data },
});

export const getCurrentPresSuccess = (data) => ({
  type: types.especialista.paciente.radiografia.getCurrentPresSuccess,
  payload: { data },
});

export const openModalDeleteRadio = (data) => ({
  type: types.especialista.paciente.radiografia.openModalDeleteRadio,
  payload: { data },
});

export const closeModalDeleteRadio = (data) => ({
  type: types.especialista.paciente.radiografia.closeModalDeleteRadio,
  payload: { data },
});

export const requestDeleteRadio = (data) => ({
  type: types.especialista.paciente.radiografia.requestDeleteRadio,
  payload: { data },
});

export const requestDeleteRadioSuccess = (data) => ({
  type: types.especialista.paciente.radiografia.requestDeleteRadioSuccess,
  payload: { data },
});

export const openModalEditRadio = (data) => ({
  type: types.especialista.paciente.radiografia.openModalEditRadio,
  payload: { data },
});

export const closeModalEditRadio = (data) => ({
  type: types.especialista.paciente.radiografia.closeModalEditRadio,
  payload: { data },
});

export const editSelectedtypesRadio = (value, item) => ({
  type: types.especialista.paciente.radiografia.editSelectedtypesRadio,
  payload: { value, item },
});

export const requestEditRadio = (data) => ({
  type: types.especialista.paciente.radiografia.requestEditRadio,
  payload: { data },
});

export const requestEditRadioSuccess = (data) => ({
  type: types.especialista.paciente.radiografia.requestEditRadioSuccess,
  payload: { data },
});

export const openModalRadioView = (data) => ({
  type: types.especialista.paciente.radiografia.openModalRadioView,
  payload: { data },
});

export const closeModalRadioView = (data) => ({
  type: types.especialista.paciente.radiografia.closeModalRadioView,
  payload: { data },
});
export const openModalTrazarRadio = (data) => ({
  type: types.especialista.paciente.radiografia.openModalTrazarRadio,
  payload: data,
});
export const closeModalTrazarRadio = (data) => ({
  type: types.especialista.paciente.radiografia.closeModalTrazarRadio,
  payload: { data },
});

export const radioViewSuccess = (data) => ({
  type: types.especialista.paciente.radiografia.radioViewSuccess,
  payload: { data },
});
