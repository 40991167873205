import { types } from '../actions';
import { all, fork, takeLatest, call, put } from 'redux-saga/effects';
import { apiCall } from '../../config/axiosApiCallWrapper';
import {
  setTramitesUser,
  setDataDetalles,
  setArchivosSeguimiento,
  setDataHistorial,
  setOpcionesDerivar,
  sucessSaveAccion,
  successTipoDocument,
} from './actions';
import { objectToFormData } from '../../helpers/Utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DashboardSagas from './dashboard/saga';
import RegistroSagas from './registro/saga';
import SeguimientoSagas from './seguimiento/Saga';
import { notificacionMesapartesWp } from '../../services/whatsapp.service';

const MySwal = withReactContent(Swal);

function* getTramitesUser({ payload }) {
  const { data, page, id } = payload;
  try {
    const response = yield call(
      apiCall,
      'POST',
      `principal/mesaPartes/getAllTramites?page=${page}`,
      { data, id }
    );
    if (response.success) {
      yield put(setTramitesUser(response.tramites));
    }
  } catch (error) {
    console.log(error);
  }
}
async function notifyWhatsapp(payload) {
  console.log('data notificaion whatsapp', payload);
  //  setLoading(true);
  await notificacionMesapartesWp({ payload })
    .then((res) => {
      if (res) {
        console.log(res);
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      // setLoading(false);
    });
}

function* getAllDataTramite({ payload }) {
  try {
    const response = yield call(
      apiCall,
      'POST',
      `principal/mesaPartes/getAllDataTramite`,
      { payload }
    );
    if (response.success) {
      yield put(setDataDetalles(response.datatramite));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getArchivosSeguimiento({ payload }) {
  try {
    const response = yield call(
      apiCall,
      'POST',
      'principal/mesaPartes/getArchivosSeguimiento',
      { payload }
    );
    if (response.success) {
      yield put(setArchivosSeguimiento(response));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getDataHistorial({ payload }) {
  try {
    const response = yield call(
      apiCall,
      'POST',
      'principal/mesaPartes/getDataHistorial',
      { payload }
    );
    if (response.success) {
      yield put(setDataHistorial(response));
    }
  } catch (error) {
    console.log(error);
  }
}

function* saveAccion({ payload }) {
  const destinoUser = JSON.parse(payload.destino);
  const accionTramite1 =
    payload.accion === 'DERIVAR' ? ' derivado' : ' rechazado';
  const dataWhatsapp = {
    destinoUser: JSON.parse(payload.destino),
    asunto: payload.asunto + ' ha sido ' + accionTramite1,
    autor: payload.autor,
  };
  const accionTramite2 =
    payload.accion === 'ACEPTAR' ? ' aceptado' : ' rechazado';
  const dataNotificacionWhatsapp = {
    destinoUser: { phone: payload.numeroAutor, full_name: payload.autor },
    asunto: payload.asunto + ' ha sido ' + accionTramite2,
  };
  const data = { ...payload, destino: JSON.stringify(destinoUser.id) };
  const temp = objectToFormData(data);
  const tempData = {
    payload: {
      id: payload.idUser,
      data: {
        fecha_inicial: '',
        fecha_final: '',
        numero: '',
        estado: 'TODOS',
        prioridad: 'TODOS',
        asunto: '',
        tipo: '0',
      },
      page: 1,
    },
  };
  try {
    const response = yield call(
      apiCall,
      'POST',
      'principal/mesaPartes/saveAccion',
      temp
    );
    if (response.success) {
      MySwal.fire({
        title: 'Accion registrada con exito',
        text: response.message,
        icon: 'success',
      });
      yield put(sucessSaveAccion());
      yield call(getTramitesUser, tempData);
      if (payload.accion === 'DERIVAR') {
        yield call(notifyWhatsapp, dataWhatsapp);
      } else {
        yield call(notifyWhatsapp, dataNotificacionWhatsapp);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getOpcionesDerivar({ payload }) {
  if (payload.estado === 'PENDIENTE' || payload.estado === 'RECHAZADO') {
    try {
      const response = yield call(
        apiCall,
        'POST',
        'principal/mesaPartes/getOpcionesDerivar',
        {}
      );
      if (response.success) {
        yield put(setOpcionesDerivar(response.opciones));
      }
    } catch (error) {
      console.log(error);
    }
  }
}

function* getTipoDocumentos() {
  try {
    const response = yield call(
      apiCall,
      'POST',
      'principal/mesaPartes/getTipoDocument',
      {}
    );
    if (response.success) {
      yield put(successTipoDocument(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* setSaveVistoArchivos({ payload }) {
  try {
    const response = yield call(
      apiCall,
      'POST',
      'principal/mesaPartes/setSaveVistoArchivos',
      { payload }
    );
    if (response.success) {
      // console.log(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchTramitesUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(types.MesaPartes.GET_TRAMITES_USER_LOADING, getTramitesUser);
}

export function* watchAllDataTramite() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(types.MesaPartes.OPEN_MODAL_DETALLES, getAllDataTramite);
}

export function* watchSeguimientoArchivos() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(
    types.MesaPartes.OPEN_MODAL_ARCHIVOS,
    getArchivosSeguimiento
  );
}

export function* watchHistorialData() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(types.MesaPartes.OPEN_MODAL_SEGUIMIENTO, getDataHistorial);
}

export function* watchSaveAccion() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(types.MesaPartes.LOADING_SAVE_ACCION, saveAccion);
}

export function* watchOpcionesDerivar() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(types.MesaPartes.OPEN_MODAL_ACCIONES, getOpcionesDerivar);
}

export function* watchTipoDocument() {
  yield takeLatest(types.MesaPartes.TIPO_DOCUMENT_LOADING, getTipoDocumentos);
}

export function* watchSetVistoArchivos() {
  yield takeLatest(
    types.MesaPartes.Seguimiento.SET_VISTO_ARCHIVOS,
    setSaveVistoArchivos
  );
}

export default function* rootSaga() {
  yield all([
    DashboardSagas(),
    RegistroSagas(),
    SeguimientoSagas(),
    fork(watchTramitesUser),
    fork(watchAllDataTramite),
    fork(watchSeguimientoArchivos),
    fork(watchHistorialData),
    fork(watchSaveAccion),
    fork(watchOpcionesDerivar),
    fork(watchTipoDocument),
    fork(watchSetVistoArchivos),
  ]);
}
