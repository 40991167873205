import { types } from '../../../actions';
import { uniqueId } from '../../../../helpers/Utils';

const initialState = {
  modalRecibos: false,
  modalPago: false,
  loadingModalRecibos: false,
  listaRecibos: [],
  carPagoItems: [],
  loadingSendRegistroPago: false,
  loadingPagoAdvance: false,
  configPagos: null,
  currentCitaSelected: null,
  presRadio: null,
  presSolicitud: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.asistente.paciente.recibos.openModalRecibos: {
      const { value } = action.payload;
      return {
        ...state,
        modalRecibos: true,
        loadingModalRecibos: true,
        currentCitaSelected: value,
      };
    }
    case types.asistente.paciente.recibos.closeModalRecibos: {
      return {
        ...state,
        modalRecibos: false,
        loadingModalRecibos: false,
        currentCitaSelected: null,
      };
    }
    case types.asistente.paciente.recibos.getReciboCitaSuccess: {
      const { value } = action.payload;
      return {
        ...state,
        loadingModalRecibos: false,
        listaRecibos: value.recibos,
      };
    }
    case types.asistente.paciente.recibos.openModalPago: {
      return {
        ...state,
        modalPago: true,
        loadingPagoAdvance: true,
      };
    }
    case types.asistente.paciente.recibos.getCitaIdPagoAdvanceSuccess: {
      const { value } = action.payload;
      return {
        ...state,
        loadingPagoAdvance: false,
        configPagos: {
          CONSULTA: value.consulta,
          TRATAMIENTO: value.tratamiento,
          RADIOGRAFIAS: 0,
          RADIOGRAFIAS_STOMAX: 0,
          OTROS: 0,
        },
        presRadio: value.presRadio,
        presSolicitud: value.dataSolicitud,
      };
    }
    case types.asistente.paciente.recibos.closeModalPago: {
      return {
        ...state,
        modalPago: false,
        presRadio: null,
        presSolicitud: null,
      };
    }
    case types.asistente.paciente.recibos.addCarPago: {
      const { value } = action.payload;
      const carPagoItems = [
        ...state.carPagoItems,
        {
          id: uniqueId(),
          ...value,
        },
      ];
      return {
        ...state,
        carPagoItems,
      };
    }
    case types.asistente.paciente.recibos.deleteCarPago: {
      const { value } = action.payload;
      const carPagoItems = state.carPagoItems.filter(
        (data) => data.id !== value.id
      );

      return {
        ...state,
        carPagoItems,
      };
    }
    case types.asistente.paciente.recibos.deleteAllCarPago: {
      return {
        ...state,
        carPagoItems: [],
      };
    }
    case types.asistente.paciente.recibos.sendRegistroPago: {
      return {
        ...state,
        loadingPagoAdvance: true,
      };
    }
    case types.asistente.paciente.recibos.sendRegistroPagoSuccess: {
      return {
        ...state,
        loadingPagoAdvance: false,
        modalPago: false,
        configPagos: null,
      };
    }
    case types.asistente.paciente.recibos.editConfigPagos: {
      const { value } = action.payload;
      return {
        ...state,
        configPagos: {
          ...state.configPagos,
          CONSULTA: state.configPagos.CONSULTA + (value ? +10 : -10),
        },
      };
    }
    case types.asistente.paciente.recibos.editConfigPagosRadio: {
      const { value } = action.payload;
      const findRadio = state.presRadio.detalles.find(
        (item) => parseInt(item.id) === parseInt(value)
      );

      let deuda = 0;
      if (findRadio) {
        deuda = findRadio.deuda;
      }

      return {
        ...state,
        configPagos: {
          ...state.configPagos,
          RADIOGRAFIAS: deuda,
        },
      };
    }
    default:
      return state;
  }
};
