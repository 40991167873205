import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import PatientData from './PatientData/reducer';
import Auth from './Auth/reducer';
import Socket from './Socket/Socket';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import AppFairdent from './Aplication/AppFairdent/Reducer';
import EspecialistaTratamiento from './especialista/paciente/actualTratamiento/reducer';
import seguimientoMP from './mesaPartes/reducer';
import balanceAdm from './Admin/BalanceSunat/Sunat/reducer';
import balanceSunatDiario from './Admin/BalanceSunat/Diario/reducer';
import GananciaSunat from './Admin/BalanceSunat/Diario/Ganancias/Reducer';
import MesaPartesDashboard from './mesaPartes/dashboard/reducer';
import MesaPartesRegistro from './mesaPartes/registro/reducer';
import MesaPartesSeguimiento from './mesaPartes/seguimiento/Reducer';
import SunatConversion from './Admin/BalanceSunat/Conversion/reducer';
import PacienteRecibos from './Asistente/Paciente/Recibos/reducer';
import EspRadiografias from './especialista/paciente/radiografia/reducer';
import RadiografiasAsistente from './Asistente/Paciente/Radiografias/reducer';
import HorasExtraRRHH from './recursosHumanos/ControlAsistencia/HorasExtras/reducer';
import Tareas from './recursosHumanos/Tareas/reducer';

const reducers = combineReducers({
  menu,
  settings,
  Auth,
  PatientData,
  Socket,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  AppFairdent,
  esp_paciente_actualTratam: EspecialistaTratamiento,
  seguimientoMP,
  admin: combineReducers({
    balanceSunat: combineReducers({
      sunat: balanceAdm,
      diario: balanceSunatDiario,
      categoria: combineReducers({
        ganancias: GananciaSunat,
      }),
      conversion: SunatConversion,
    }),
  }),
  asistente: combineReducers({
    paciente: combineReducers({
      recibos: PacienteRecibos,
      radiografias: RadiografiasAsistente,
    }),
  }),
  mesaPartes: combineReducers({
    registro: MesaPartesRegistro,
    dashboard: MesaPartesDashboard,
    seguimiento: MesaPartesSeguimiento,
  }),
  especialista: combineReducers({
    paciente: combineReducers({
      radiografia: EspRadiografias,
    }),
  }),
  recursosHumanos: combineReducers({
    controlAsistencia: combineReducers({
      horasExtra: HorasExtraRRHH,
    }),
    tareas: Tareas,
  }),
});

export default reducers;
